import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { TemplateAbout } from '../templates';
import { Heading, Element, Paragraph } from '@arturpol/style-guide/src/components';
import { Link } from '../components';
export const meta = {
  title: 'About us',
  type: 'page',
  hint: 'Learn more about us',
  order: 64,
  seoTitle: 'What Is Human Design?',
  seoDescription: 'Human Design — the most efficient way we found to launch a product and turn satisfied users into long-term customers.'
};
export const banner = {
  title: <>Cutting edge design <Heading as="div" size={6} mdxType="Heading">without cutting corners</Heading></>,
  lead: 'We’re proud of being a small agency making a thousandfold impact on people’s lives with products we design for real humans.',
  content: <>
            <Heading importance={5} size={4} mdxType="Heading">What makes us different?</Heading>
            <Paragraph mdxType="Paragraph">We help our partners achieve a balanced synergy of the right business model, unique product selling points and great user experience.</Paragraph>
            <Paragraph mdxType="Paragraph">We maximize product value while minimizing development costs because we know precisely how to plan systems for development.</Paragraph>
            <Paragraph mdxType="Paragraph">This sums up our Human Design approach: We use the most direct and efficient methods to launch your systems and quickly turn satisfied users into long-term customers.</Paragraph>
        </>,
  image: {
    alt: 'About Systems for Humans',
    shape0Line0: 'Product-',
    shape0Line1: 'market fit',
    shape1Line0: 'User',
    shape1Line1: 'experience',
    shape2Line0: 'Technology',
    shapeIntersection: 'Us'
  }
};
export const process = {
  title: 'How do we work?',
  subtitle: 'The Human Design Process',
  steps: [{
    title: 'Research',
    content: 'Learning what a user feels, thinks and needs comes first. We must fit these essential expectations into market reality while showcasing your best <abbr title="Unique Selling Proposition">USP</abbr>.',
    img: {
      src: '/images/flow-research.svg',
      alt: 'Research'
    }
  }, {
    title: 'Blueprint',
    content: 'We use a simple prototype as the most cost-effective way to validate your idea, find investors, get accurate development estimates and tweak your business model.',
    img: {
      src: '/images/flow-blueprint.svg',
      alt: 'Blueprint'
    }
  }, {
    title: 'Design teaser',
    content: 'Next, it’s time to test how your audience responds to your product look and feel. We iterate and refine based on your feedback until we know you will win users’ hearts and minds.',
    img: {
      src: '/images/flow-teaser.svg',
      alt: 'Design teaser'
    }
  }, {
    title: 'Prototype',
    content: 'Clickable prototypes work as a ‘single source of truth’ for you and your development team; this is how we make sure nothing falls through the cracks.',
    img: {
      src: '/images/flow-prototype.svg',
      alt: 'Prototype'
    }
  }, {
    title: 'Handoff',
    content: 'We provide you and your team with a comprehensive style guide including typography, iconography and reusable <abbr title="User Interface">UI</abbr> components. Always online and easy to use.',
    img: {
      src: '/images/flow-handoff.svg',
      alt: 'Handoff'
    }
  }]
};
export const team = {
  title: 'Meet our team',
  people: [{
    id: 'artur',
    name: 'Artur Polkowski',
    title: 'Product Designer',
    bio: 'Artur combines natural empathy and analytical skill as an IT engineer. His daily goal is to refine projects into the most elegant, productive and efficient cross platform applications.',
    img: {
      src: '/images/team/artur_polkowski_640.jpg',
      alt: 'Artur Polkowski',
      density: [1, 2, 4]
    },
    linkedInLink: {
      url: 'https://www.linkedin.com/in/arturpolkowski/',
      hint: 'Let\'s connect on LinkedIn'
    }
  }, {
    id: 'marie',
    name: 'Marie Filonenko',
    title: 'Brand Designer',
    bio: 'With a multicultural background, expertise in graphic design and photography and a lifetime passion for illustration, Marie is the ideal mix of new media storyteller and a brand creator.',
    img: {
      src: '/images/team/marie_filonenko_640.jpg',
      alt: 'Marie Filonenko',
      density: [1, 2, 4]
    },
    linkedInLink: {
      url: 'https://www.linkedin.com/in/mariefilonenko/',
      hint: 'Let\'s connect on LinkedIn'
    }
  }]
};
export const faq = {
  title: 'Frequently Asked Questions',
  questions: [{
    title: 'Do you do development?',
    content: 'No, and this gives you two big advantages: First, our systems and designs aren’t biased about development techniques; we focus on what truly matters for your users. Second, our approach also means your assignment to a developer is closed to ‘endless development’ tactics. Simply say “build this” instead of asking what, why or how to develop.'
  }, {
    title: 'Why not entrust design to a software house?',
    content: 'Software development firms aren’t single-minded specialists in User Experience or User Interface design so they make system layout and design mistakes. The most profitable strategy for many developers is to provide mediocre design, hook you into a contract and charge more for inevitable changes – including more development changes to match.'
  }, {
    title: 'Why not hire a freelancer?',
    content: 'Even just searching for the right-fit freelancer can be a tremendous waste of time and money. Lone freelancers come and go, can’t offer services beyond their field of expertise (like a UX designer working on animations) and seldom have enough experience to assist and advise you in a product launch. A full function company usually fits the ticket better.'
  }, {
    title: 'Where are you located?',
    content: 'We’re based in Poznań, Poland and have worked remotely since 2012. Most of our clients come from the US, Europe (including the UK), Australia and New Zealand.'
  }]
};
export const rules = {
  title: 'Are we the best match?',
  items: [{
    id: 'quality',
    title: 'Quality first',
    content: 'Dedication counts and we usually work on just 1-2 projects at a time. This is how we preserve the flexibility to explore, experiment and bring you the best result.',
    img: {
      src: '/images/match-quality.jpg',
      alt: 'Quality first',
      density: [1, 2],
      breakpoint: ['s', 'l']
    }
  }, {
    id: 'value',
    title: 'Maximize value',
    content: 'We rapid-prototype to avoid wasting resources. This quickly yields an attractive and easy-to-use solution that often spotlights advantages you hadn’t realized before.',
    img: {
      src: '/images/match-value.jpg',
      alt: 'Maximize value',
      density: [1, 2],
      breakpoint: ['s', 'l']
    }
  }, {
    id: 'creative',
    title: 'Outside the box?',
    content: 'We love a challenge! Bring us your complex problems and a desire for a state-of-the-art application, and we’ll optimize something wonderful!',
    img: {
      src: '/images/match-creativity.jpg',
      alt: 'Outside the box',
      density: [1, 2],
      breakpoint: ['s', 'l']
    }
  }, {
    id: 'deadline',
    title: 'Sensible deadline',
    content: 'Good design requires time. Fast-forwarding, ad-hoc solutions and hasty decisions are cardinal sins of poor management and we avoid them at all costs.',
    img: {
      src: '/images/match-deadline.jpg',
      alt: 'Sensible deadline',
      density: [1, 2],
      breakpoint: ['s', 'l']
    }
  }, {
    id: 'callout',
    title: 'Let’s start',
    content: 'Schedule a free 45-minute workshop to explore your goals and user needs.',
    img: {
      src: '/images/match-start.jpg',
      alt: 'Let’s start',
      density: [1, 2],
      breakpoint: ['s', 'l']
    },
    cta: {
      label: 'Make an appointment',
      hint: 'Make an appointment',
      url: '#/contact/'
    }
  }]
};
export const contact = {
  details: {
    title: 'Contact details',
    content: <Paragraph mdxType="Paragraph">Send us a <Link url="#/contact/" mdxType="Link">message</Link>, write an email: <Link url="mailto:hi@systemsforhumans.com" mdxType="Link">hi@systemsforhumans.com</Link> or simply call: +48 601 066 287 (Mon-Fri 10 am-6 pm CET).</Paragraph>
  },
  company: {
    title: 'Company',
    entities: [{
      name: 'Artur Polkowski IT Services',
      address: 'Brzozowa 10, 62095 Murowana Goslina, PL',
      vat: 'VAT EU: PL777-299-72-09'
    }, {
      name: 'Mariia Filonenko Services',
      address: 'Brzozowa 10, 62095 Murowana Goslina, PL',
      vat: 'VAT EU: PL777-335-52-98'
    }]
  },
  cta: {
    url: '#/contact/',
    hint: 'Contact us',
    label: 'Contact us'
  }
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  banner,
  process,
  team,
  faq,
  rules,
  contact,
  _frontmatter
};
const MDXLayout = TemplateAbout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      